<template>
	<b-row>
		<b-col v-if="!hideDepartment" sm="12" md="12" :lg="departmentDesktopSize" :xl="departmentDesktopSize">
			<b-form-group v-uni-for="'new-expense_ticket-department'" v-if="!isFilmSingle" :label="FormMSG(1, 'Department')">
				<b-form-select
					v-model="selectedDepartment"
					v-uni-id="'new-expense_ticket-department'"
					:disabled="disabled"
					:options="allDepartements"
					:placeholder="FormMSG(589, 'Select a department')"
					@input="departmentChange"
					:class="{ 'is-invalid': isSubmitted && $v.selectedDepartment.$error }"
				/>
				<div v-if="isSubmitted && !$v.selectedDepartment.required" class="invalid-feedback">
					{{ FormMSG(8, 'Please, choose a department') }}
				</div>
			</b-form-group>
		</b-col>
		<b-col v-if="!hideCategory" sm="12" md="12" :lg="categoryDesktopSize" :xl="categoryDesktopSize">
			<b-form-group v-uni-for="'new-expense_ticket-category'" :label="FormMSG(2, 'Category')" :class="{ 'cursor-not-allowed': disabled }">
				<treeselect
					v-model="selectedCategory"
					:disabled="disabled"
					:multiple="false"
					:clearable="false"
					:options="allCategories"
					@input="categoryChange"
					:disable-branch-nodes="true"
					:default-expand-level="1"
					:placeholder="FormMSG(569, 'Select a category')"
					:class="{ 'is-invalid': isSubmitted && $v.selectedCategory.$error }"
				>
					<div slot="option-label" slot-scope="{ node }">
						<div class="treeselect-label" :title="node.label">{{ node.label }}</div>
					</div>
					<div slot="value-label" slot-scope="{ node }">
						<span v-if="isValueInOptions(selectedCategory)">{{ node.label }}</span>
						<span v-else>{{ FormMSG(569, 'Select a category') }}</span>
					</div>
				</treeselect>
				<div v-if="isSubmitted && $v.selectedCategory.$error" class="invalid-feedback">
					{{ FormMSG(5, 'Please, choose a category') }}
				</div>
			</b-form-group>
		</b-col>
		<b-col
			sm="12"
			md="12"
			:lg="transportDesktopSize"
			:xl="transportDesktopSize"
			v-if="+coTwoCategorie === 4 && selectedCategory > 0 && !hideTransportationMean"
		>
			<b-form-group :label="FormMSG(4, 'Transportation mean')" label-for="optionsForTransportMean">
				<b-form-select
					id="optionsForTransportMean"
					v-model="selectedSubCategory"
					:disabled="disabled"
					:options="optionsForTransportMean"
					@change="handleSubCategoryChange"
				/>
			</b-form-group>
		</b-col>
	</b-row>
</template>

<script>
// import { mapGetters } from 'vuex'
import { store } from '@/store';
import { isNil, objReducer, removeAttributeTree, greaterThanZero } from '@/shared/utils';
import languageMessages from '@/mixins/languageMessages';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { createUniqIdsMixin } from 'vue-uniq-ids';
const uniqIdsMixin = createUniqIdsMixin(/* options */);
import { validationMixin } from 'vuelidate';
import { required, decimal } from 'vuelidate/lib/validators';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { getBudgetHierarchicalCategoryJson } from '@/cruds/budget.crud';
import { getDepartments } from '@/cruds/department.crud';
// import gql from 'graphql-tag';
import { getValidations } from '@/cruds/validations.crud';
import { GetEncodingValidation } from '@/cruds/users.crud';

export default {
	name: 'DepartmentSelection',
	mixins: [languageMessages, uniqIdsMixin, validationMixin, isSingleProjectMixin],
	components: {
		Treeselect
	},
	props: {
		department: {
			type: Number,
			required: false,
			default: null
		},
		category: {
			type: Number,
			required: false,
			default: null
		},
		editData: {
			type: Object,
			required: false,
			default: null
		},
		hideCategory: {
			type: Boolean,
			required: false,
			default: false
		},
		hideDepartment: {
			type: Boolean,
			required: false,
			default: false
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		isSubmitted: {
			type: Boolean,
			required: false,
			default: false
		},
		hideTransportationMean: {
			type: Boolean,
			default: false,
			required: false
		},
		categoryForTransportation: {
			type: Boolean,
			default: false,
			required: false
		},
		formType: {
			type: Number,
			required: false,
			default: 0
		},
		isNew: {
			type: Boolean,
			required: false,
			default: true
		},
		departmentDesktopSize: {
			type: [String, Number],
			required: false,
			default: 12
		},
		categoryDesktopSize: {
			type: [String, Number],
			required: false,
			default: 12
		},
		transportDesktopSize: {
			type: [String, Number],
			required: false,
			default: 12
		},
		removeDepZero: {
			type: Boolean,
			required: false,
			default: false
		},
		forExpense: {
			type: Boolean,
			default: false
		},
		forPo: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			selectedDepartment: 0,
			selectedCategory: 0,
			selectedSubCategory: null,
			isNewer: false,
			allCategories: [],
			coTwoCategorie: 0,
			elemForm: {
				department: null,
				subCategory: 0,
				category: 0
			},
			userValidationLevelData: [],
			allDepartements: [
				{
					value: 0,
					message: 'test'
				}
			],
			isFirstLoad: true,
			defaultDepartment: 0,
			defaultFormType: 0,

			validationDepartments: []
		};
	},
	computed: {
		optionsForTransportMean() {
			let transports = this.FormMenu(905);
			transports = transports.map((t) => ({
				value: t.value,
				text: t.text
			}));

			const text = this.FormMSG(6, 'Select a transportation');

			transports = transports.sort(function compare(a, b) {
				if (a.text.toLowerCase() < b.text.toLowerCase()) return -1;
				if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
				return 0;
			});

			return [{ value: null, text }, ...transports];
		},
		mapCategories() {
			const _t = this.FormMSG;
			const categories = this.allCategories.map((c) => ({
				value: c.value,
				text: c.message
			}));
			console.log(categories);
			const text = _t(332, 'Select a Category');

			return [{ value: null, text }, ...categories];
		},
		forEntryCrew() {
			if (this.$route.name === 'expensesEntryCrew' || this.$route.name === 'expensesEntryCrewDetails' || this.$route.name === 'poEntryCrew') {
				return true;
			}

			return false;
		}
	},
	watch: {
		isNew: {
			handler(bool) {
				if (!isNil(bool)) {
					this.isNewer = bool;
				}
			},
			immediate: true,
			deep: true
		},
		elemForm: {
			handler(elem) {
				if (!this.isFirstLoad) {
					this.$emit('change', elem);
				}
				this.isFirstLoad = false;
			},
			immediate: true,
			deep: true
		},
		isSubmitted: {
			handler(n) {
				if (!isNil(n) && n === true) {
					this.$v.$touch();
					if (this.$v.$invalid) {
						this.$emit('department:selector:invalid', true);
					} else {
						this.$emit('department:selector:invalid', false);
					}
				}
			},
			deep: true,
			immediate: true
		},
		department: {
			handler(value) {
				if (!isNil(value)) {
					this.defaultDepartment = value;
				}
			},
			deep: true,
			immediate: true
		},
		formType: {
			handler(value) {
				if (!isNil(value)) {
					this.defaultFormType = value;
				}
			},
			deep: true,
			immediate: true
		},
		editData: {
			handler(value) {
				if (!isNil(value)) {
					this.$nextTick(async () => {
						await this.setAutoSelectElement();
					});
				}
			},
			immediate: true
		}
	},
	async created() {
		const { action, type } = this.$route.params;
		if (type) {
			this.defaultFormType = parseInt(type, 10);
		}
		if (action === 'n') {
			this.isNewer = true;
			this.defaultDepartment = store.state.myProfile.department;
		}

		if (action === 'u') {
			this.isNewer = false;
			this.defaultDepartment = this.editData.department;
		}

		if (!isNil(this.editData)) {
			this.elemForm = objReducer(['department', 'category', 'subCategory'], this.editData);
			if (this.editData.itemCategory) {
				this.elemForm.category = this.editData.itemCategory;
			}
		}

		await this.getValidationLevel();
		await this.getDepartment();
		await this.getCategories();
		await this.setAutoSelectElement();

		if (this.hideDepartment) {
			await this.getCategories(this.defaultDepartment);
		}
	},
	methods: {
		isValueInOptions(valueId) {
			// Recursive function to check if the value exists in the options list
			const findValue = (options) => {
				for (const option of options) {
					if (option.id === valueId) return true;
					if (option.children && findValue(option.children)) return true;
				}
				return false;
			};
			return findValue(this.allCategories);
		},
		async getValidationLevel() {
			await getValidations(0)
				.then((result) => {
					for (const userVal of result) {
						if (userVal.user.id === store.state.myProfile.id) {
							this.userValidationLevelData.push(userVal);
						}
					}
				})
				.catch((error) => {
					this.erreur = error;
				});
		},
		async getDepartment() {
			this.allDepartements = [];
			const mapDepartments = (deps) => {
				const departments = deps.map((d) => ({
					value: d.value,
					text: d.message
				}));
				return departments;
			};
			// alert(validationLevel);
			await getDepartments().then((result) => {
				this.allDepartements = mapDepartments(result);
				if (this.removeDepZero) {
					this.allDepartements = this.allDepartements.filter((val) => val.value !== 0);
				}
			});
		},
		async setAutoSelectElement() {
			if (isNil(this.editData)) {
				const { department } = store.state.myProfile;
				if (this.isNewer) {
					this.selectedDepartment = store.state.myProfile.department;
					await this.departmentChange(this.selectedDepartment);
					if (this.defaultFormType === 2) {
						this.selectedCategory = 0;
					}
					this.isNewer = false;
				} else {
					this.selectedDepartment = !isNil(department) ? department : 0;
					await this.departmentChange(this.selectedDepartment);
				}
				return;
			}

			this.coTwoCategorie = this.editData.coTwoCategory;
			let { department, category, subCategory } = this.editData;
			if (isNil(department)) {
				department = store.state.myProfile.department;
			}

			if (isNil(category)) {
				category = this.category;
			}

			if (!this.hideDepartment) {
				this.departmentChange(department, true);
			}

			if (this.isNewer) {
				this.selectedDepartment = store.state.myProfile.department;
				await this.departmentChange(this.selectedDepartment);
				this.isNewer = false;
			} else {
				this.selectedDepartment = department;
			}
			this.selectedCategory = !isNil(category) ? category : 0;
			this.selectedSubCategory = !isNil(subCategory) ? subCategory : 0;

			if (!isNil(subCategory)) {
				this.selectedSubCategory = subCategory;
			} else {
				if (this.coTwoCategorie !== 4) {
					this.handleSubCategoryChange(0);
				}
			}
		},
		// Handle selection changes
		async departmentChange(departementID, isInit = false) {
			if (!isNil(departementID)) {
				this.elemForm.department = parseInt(departementID, 10);
				// await this.getCategories(departementID);
				// this.selectedCategory = 0;
				// if (!isInit) {
				// 	this.selectedCategory = 0;
				// }
			}
			if (!isNil(this.$v)) {
				this.$v.$touch();
				if (this.$v.$invalid) {
					this.$emit('department:selector:invalid', true);
				} else {
					this.$emit('department:selector:invalid', false);
				}
			}
		},
		searchTree(tree, key, id) {
			for (const node of tree) {
				if (node.id === id) {
					return node[key];
				}
				if (node.children) {
					const result = this.searchTree(node.children, key, id);
					if (result) return result;
				}
			}
			return null;
		},
		categoryChange(value, event) {
			if (this.allCategories.length > 0) {
				let CategoryData = this.searchTree(this.allCategories, 'data', value);
				if (CategoryData) {
					this.coTwoCategorie = CategoryData.coTwoCategory;
				}
			}
			if (this.coTwoCategorie !== 4) this.handleSubCategoryChange(0);
			this.elemForm.category = parseInt(value, 10);
			// this.$v.$touch();
			// if (this.$v.$invalid) {
			// 	this.$emit('department:selector:invalid', true);
			// } else {
			// 	this.$emit('department:selector:invalid', false);
			// }
		},
		/**
		 * @param {Number} id - DepartementID
		 */
		async getCategories(id) {
			this.allCategories = [];
			//console.log("this.userValidationLevelData.validations",this.userValidationLevelData.validations)
			const getValidationLevel = async () => {
				// alert('tafiditra ato');
				let validationLevel = 0;
				for (const validation of this.userValidationLevelData) {
					validationLevel = validation.validationLevelExp;
				}
				return validationLevel;
			};

			const traverseTreeAndUpdate = async (nodes, isSum) => {
				nodes.forEach((node) => {
					if (node.data.isSum === isSum && !node.children) {
						node.isDisabled = true;
					}
					if (node.children) {
						traverseTreeAndUpdate(node.children, isSum);
					}
				});
				return nodes;
			};

			if (isNil(this.userValidationLevelData)) {
				this.userValidationLevelData = [];
			}

			/**
			 * this is a section new fix by Lanja
			 * Due to this ticket: https://app.clickup.com/t/86bxrqxqe
			 */
			// check if user can view budget global to set use app choice parameters to true or false.
			let setUseAppChoice = !store.canViewGlobalBudget();
			if (this.forExpense) {
				setUseAppChoice = !store.canEditExpense() && setUseAppChoice;
			}
			if (this.forPo) {
				setUseAppChoice = !store.canEditPo() && setUseAppChoice;
			}

			// init first value for the budget categories.
			const initCategory = {
				id: null,
				label: this.FormMSG(9, 'Select a category')
			};

			// check if defaultFormType === 0 or 1 then get all budget category according to that
			if (this.defaultFormType === 0 || this.defaultFormType === 1) {
				const result = await getBudgetHierarchicalCategoryJson(-2, true, 0, -1, setUseAppChoice);

				this.allCategories = removeAttributeTree(result, 'children', null);
			}
			// else if defaultFormType === 2 then get all budget category according to that
			else if (this.defaultFormType === 2) {
				const result = await getBudgetHierarchicalCategoryJson(-2, true, 0, 4, setUseAppChoice);

				const resultUpdadted = await traverseTreeAndUpdate(result, true);

				this.allCategories = removeAttributeTree(resultUpdadted, 'children', null);
			}
			// if get no results
			if (this.allCategories.length === 0) {
				this.allCategories.unshift(initCategory);
			}
			/** END OF THE NEW FIX */

			/**
			 * Due to this ticket: https://app.clickup.com/t/86bxrqxqe
			 * I had perform a new code refactoring.
			 * In case of problem, for an emergency, uncomment the following code (the original)
			 * and comment the bellow code wrapped in the section *new fix by Lanja*.
			 */
			// if (this.isNewer) {
			// 	if (this.defaultFormType === 0 || this.defaultFormType === 1) {
			// 		const validationLevel = await getValidationLevel();
			// 		let result;

			// 		if (validationLevel === 2) {
			// 			result = await getBudgetHierarchicalCategoryJson(-2, true, 0, -1, false);
			// 		} else {
			// 			result = await getBudgetHierarchicalCategoryJson(-2, true, null, null, true);
			// 		}

			// 		const initCategory = {
			// 			id: null,
			// 			label: this.FormMSG(9, 'Select a category')
			// 		};

			// 		this.allCategories = removeAttributeTree(result, 'children', null);

			// 		if (this.allCategories.length === 0) {
			// 			this.allCategories.unshift(initCategory);
			// 		}
			// 	}

			// 	if (this.defaultFormType === 2) {
			// 		const validationLevel = await getValidationLevel();
			// 		if (validationLevel === 2) {
			// 			await getBudgetHierarchicalCategoryJson(-2, true, 0, 4, false).then(async (result) => {
			// 				const initCategory = {
			// 					id: null,
			// 					label: this.FormMSG(9, 'Select a category')
			// 				};

			// 				await traverseTreeAndUpdate(result, true).then((results) => {
			// 					this.allCategories = removeAttributeTree(results, 'children', null);

			// 					if (this.allCategories.length === 0) {
			// 						this.allCategories.unshift(initCategory);
			// 					}
			// 				});
			// 			});
			// 		} else {
			// 			await getBudgetHierarchicalCategoryJson(-2, true, 0, 4, true).then(async (result) => {
			// 				const initCategory = {
			// 					id: null,
			// 					label: this.FormMSG(9, 'Select a category')
			// 				};

			// 				await traverseTreeAndUpdate(result, true).then((results) => {
			// 					this.allCategories = removeAttributeTree(results, 'children', null);

			// 					if (this.allCategories.length === 0) {
			// 						this.allCategories.unshift(initCategory);
			// 					}
			// 				});
			// 			});
			// 		}
			// 	}
			// } else {
			// 	if (this.defaultFormType === 0 || this.defaultFormType === 1) {
			// 		const validationLevel = await getValidationLevel();
			// 		let result;

			// 		if (validationLevel === 2) {
			// 			result = await getBudgetHierarchicalCategoryJson(-2, true, 0, -1, false);
			// 		} else {
			// 			result = await getBudgetHierarchicalCategoryJson(-2, true, null, null, true);
			// 		}

			// 		const initCategory = {
			// 			id: null,
			// 			label: this.FormMSG(9, 'Select a category')
			// 		};

			// 		this.allCategories = removeAttributeTree(result, 'children', null);

			// 		if (this.allCategories.length === 0) {
			// 			this.allCategories.unshift(initCategory);
			// 		}
			// 	}

			// 	if (this.defaultFormType === 2) {
			// 		const validationLevel = await getValidationLevel();
			// 		if (validationLevel === 2) {
			// 			await getBudgetHierarchicalCategoryJson(-2, true, 0, 4, false).then(async (result) => {
			// 				const initCategory = {
			// 					id: null,
			// 					label: this.FormMSG(9, 'Select a category')
			// 				};

			// 				await traverseTreeAndUpdate(result, true).then((results) => {
			// 					this.allCategories = removeAttributeTree(results, 'children', null);

			// 					if (this.allCategories.length === 0) {
			// 						this.allCategories.unshift(initCategory);
			// 					}
			// 				});
			// 			});
			// 		} else {
			// 			await getBudgetHierarchicalCategoryJson(-2, true, 0, 4, true).then(async (result) => {
			// 				const initCategory = {
			// 					id: null,
			// 					label: this.FormMSG(9, 'Select a category')
			// 				};

			// 				await traverseTreeAndUpdate(result, true).then((results) => {
			// 					this.allCategories = removeAttributeTree(results, 'children', null);

			// 					if (this.allCategories.length === 0) {
			// 						this.allCategories.unshift(initCategory);
			// 					}
			// 				});
			// 			});
			// 		}
			// 	}
			// }
		},
		handleSubCategoryChange(subCategoryId) {
			this.elemForm.subCategory = parseInt(subCategoryId, 10);
			this.selectedSubCategory = subCategoryId;
			this.$v.$touch();
			if (this.$v.$invalid) {
				this.$emit('department:selector:invalid', true);
			} else {
				this.$emit('department:selector:invalid', false);
			}
		},
		async getValidationDepartments() {
			await GetEncodingValidation(store.getCurrentProjectConfig().id, store.userID()).then((result) => {
				this.validationDepartments = result.department;
			});
		}
	},
	validations() {
		let patterns = {
			selectedDepartment: {
				required
			},
			selectedCategory: {
				required,
				decimal
			}
		};

		if (+this.coTwoCategorie === 4 && !this.hideTransportationMean) {
			patterns = {
				...patterns,
				selectedSubCategory: {
					required
				}
			};
		}
		// selectedSubCategory: {
		// 	required
		// }
		// parseInt(coTwoCategorie, 10) === 4 && !hideTransportationMean
		return patterns;
	}
};
</script>

<style lang="scss" scoped>
:deep(.cursor-not-allowed) {
	cursor: not-allowed;
}
</style>
